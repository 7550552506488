<template>
  <div class="flex flex-col justify-center items-center h-[760px] w-full mt-[65px]">
    <div class="w-full max-w-[1680px] mx-auto px-0">
      <div class="mb-8">
        <div class="flex flex-wrap mb-6">
          <div class="flex-1">
            <div class="flex gap-10 w-full">
              <section class="flex flex-col w-full">
                <div class="mb-[64px] 2xl-1440:mb-[25px]">
                  <h1 class="text-[50px] mt-[80px] font-semibold mb-[20px] 2xl-1440:text-[25px]">¿Qué es la reputación online?</h1>
                  <p class="text-[24px] font-medium text-[#333333]">
                    La percepción de calidad y confianza que un hotel genera entre los usuarios de plataformas digitales
                  </p>
                </div>
                <div>
                  <h1 class="text-[35px] font-semibold mb-[20px] 2xl-1440:text-[25px]">¿Qué elementos la componen?</h1>
                  <div class="flex gap-10 w-full">
                    <!-- Sección 1: Nota media -->
                    <section 
                      class="relative flex flex-col justify-center items-start gap-3 w-[200px] h-[200px] pl-4 overflow-visible" 
                      style="background-image: url('/img/presentacion/2.th.nota-media-op.svg'); background-size: 155px auto; background-repeat: no-repeat; background-position: right;">
                      <div class="absolute left-0 inset-y-10 w-[3px] bg-[#333]"></div>
                      <div class="flex flex-col items-start w-full justify-start">
                        <img src="/img/presentacion/2.th.nota-media.svg" class="h-10 w-10 svg-black mr-2" alt="Nota media">
                        <p class="text-[24px] font-medium text-[#333]">Nota media</p>
                      </div>
                    </section>
                    
                    <!-- Sección 2: Número de reseñas -->
                    <section 
                      class="relative flex flex-col justify-center items-start gap-3 w-[200px] h-[200px] pl-4 overflow-visible" 
                      style="background-image: url('/img/presentacion/2.th.respuestas-reseñas-op.svg'); background-size: 155px auto; background-repeat: no-repeat; background-position: right;">
                      <div class="absolute left-0 inset-y-10 w-[3px] bg-[#333] m-auto"></div>
                      <div class="flex flex-col items-start w-full justify-start">
                        <img src="/img/presentacion/2.th.respuestas-reseñas.svg" class="h-10 w-10 svg-black mr-2" alt="Número de reseñas">
                        <p class="text-[24px] font-medium text-[#333]">Número de reseñas</p>
                      </div>
                    </section>
                    
                    <!-- Sección 3: Tasa de respuesta -->
                    <section 
                      class="relative flex flex-col justify-center items-start gap-3 w-[200px] h-[200px] pl-4 overflow-visible" 
                      style="background-image: url('/img/presentacion/2.th-numero-reseñas-op.svg'); background-size: 155px auto; background-repeat: no-repeat; background-position: right;">
                      <div class="absolute left-0 inset-y-10 w-[3px] bg-[#333] m-auto"></div>
                      <div class="flex flex-col items-start w-full justify-start">
                        <img src="/img/presentacion/2.th-numero-reseñas.svg" class="h-10 w-10 svg-black mr-2" alt="Tasa de respuesta">
                        <p class="text-[24px] font-medium text-[#333]">Tasa de respuesta</p>
                      </div>
                    </section>
                  </div>
                </div>
              </section>
            </div>
          </div>
          <div class="flex-1 flex justify-center">
            <img src="/img/presentacion/ResenasSVG.svg" class="max-w-full h-auto object-contain" alt="Que hacemos">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
