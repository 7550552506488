<template>
  <div class="flex flex-col justify-center items-center h-[760px] pb-20 w-full mt-[85px]">
    <div class="w-full max-w-[1680px] mx-auto px-0">
      <div class="mb-8">
        <div class="flex gap-3 mb-2">
          <div class="flex flex-col w-[90%]">
            <p class="font-medium text-[50px] text-[#333333] mt-[69px]">
              Mejorando la experiencia de tus huéspedes mediante la digitalización de sus estancias
            </p>
            <div class="flex gap-[64px] 2xl-1440:gap-[30px] w-full mt-[100px]">
              <section 
                class="relative flex flex-col justify-center items-start gap-3 w-[390px] h-[200px] pl-4 bg-gray-100 bg-opacity-20 overflow-visible" 
                style="background-image: url('/img/presentacion/2.th.webapp-hoster-op.svg'); background-size: 195px auto; background-repeat: no-repeat; background-position: left 100px center;">
                
                <div class="absolute left-0 top-6 bottom-[92px] w-[3px] bg-[#333]"></div>
                
                <img src="/img/presentacion/2.th.webapp-hoster.svg" class="h-12 w-12 mr-2" alt="Plataformas">
                <h1 class="text-[24px] 2xl-1440:text-[35px] font-semibold">2 Plataformas</h1>
                
                <h1 class="text-[36px] 2xl-1440:text-[29px] font-semibold">WebApp - Hoster</h1>
              </section>
              
            </div>
          </div>
          <div class="flex flex-col items-end w-[90%] justify-end relative">
            <img src="/img/presentacion/MovilMonitorPNG.png" alt="Hoster" class="w-full h-full" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
