<template>
  <div class="flex justify-center items-center mt-[40px] 2xl-1440:mt-[15px] pr-[120px] pl-[120px] 2xl-1440:pr-[140px] 2xl-1440:pl-[140px]">
    <div class="grid grid-cols-3 gap-[64px]">
      
      <!-- Columna WebApp -->
      <div class="flex flex-col gap-0 w-[520px]">
        <h1 class="font-semibold text-[35px] 2xl-1440:text-[21px] custom-span">WebApp</h1>
        <hr class="border border-2 w-[450px] border-black">
        <!-- Ítems de WebApp -->
        <div class="py-[24px] 2xl-1440:py-3 flex items-center rounded-[10px]">
          <img src="/img/icons/2.TH.Item_icon_Card_Pricing.svg" alt="Icono Alojamiento" class="w-[64px] h-[64px] mr-4">
          <h1 class="text-[29px] font-semibold 2xl-1440:text-[20px]">Alojamiento</h1>
        </div>
        <div class="py-[24px] 2xl-1440:py-3 flex items-center rounded-[10px]">
          <img src="/img/presentacion/destino.svg" alt="Icono Destino" class="w-[64px] h-[64px] mr-4">
          <p class="text-[29px] font-semibold 2xl-1440:text-[20px]">Destino</p>
        </div>
        <div class="py-[24px] 2xl-1440:py-3 flex items-center rounded-[10px]">
          <img src="/img/presentacion/experiencias.svg" alt="Icono Experiencias" class="w-[64px] h-[64px] mr-4">
          <p class="text-[29px] font-semibold 2xl-1440:text-[20px]">Experiencias</p>
        </div>
        <div class="py-[24px] 2xl-1440:py-3 flex items-center rounded-[10px]">
          <img src="/img/presentacion/suite.svg" alt="Icono Chat" class="w-[64px] h-[64px] mr-4">
          <p class="text-[29px] font-semibold 2xl-1440:text-[20px]">Chat</p>
        </div>
        <div class="py-[24px] 2xl-1440:py-3 flex items-center rounded-[10px]">
          <img src="/img/presentacion/seguimiento.svg" alt="Icono Seguimiento" class="w-[64px] h-[64px] mr-4">
          <p class="text-[29px] font-semibold 2xl-1440:text-[20px]">Seguimiento</p>
        </div>
      </div>

      <!-- Columna Hoster -->
      <div class="flex flex-col gap-0 w-[520px]">
        <h1 class="font-semibold text-[35px] 2xl-1440:text-[21px] custom-span">Hoster</h1>
        <hr class="border border-2 w-[450px] border-black">
        <!-- Ítems de Hoster -->
        <div class="py-[24px] 2xl-1440:py-3 flex items-center rounded-[10px]">
          <img src="/img/presentacion/gestion.svg" alt="Icono Reseñas" class="w-[64px] h-[64px] mr-4">
          <p class="text-[29px] font-semibold 2xl-1440:text-[20px]">Reseñas</p>
        </div>
        <div class="py-[24px] 2xl-1440:py-3 flex items-center rounded-[10px]">
          <img src="/img/presentacion/estancias2.png" alt="Icono Estancias" class="w-[64px] h-[64px] mr-4">
          <p class="text-[29px] font-semibold 2xl-1440:text-[20px]">Estancias</p>
        </div>
        <div class="py-[24px] 2xl-1440:py-3 flex items-center rounded-[10px]">
          <img src="/img/presentacion/2.TH.Item_icon_Card_Pricing.png" alt="Icono Comunicaciones" class="w-[64px] h-[64px] mr-4">
          <p class="text-[29px] font-semibold 2xl-1440:text-[20px]">Comunicaciones</p>
        </div>
        <div class="py-[24px] 2xl-1440:py-3 flex items-center rounded-[10px]">
          <img src="/img/presentacion/webapp.svg" alt="Icono Personalización" class="w-[64px] h-[64px] mr-4">
          <p class="text-[29px] font-semibold 2xl-1440:text-[20px]">Personalización</p>
        </div>
      </div>

      <!-- Columna Servicios adicionales -->
      <div class="flex flex-col gap-0 w-[750px] bg-[#F1FAF8] pt-[27px] pr-[24px] pb-[22px] h-[600px] pl-[24px] rounded-[20px] shadow-[4px_4px_8px_0_rgba(0,0,0,0.25)]">
        <h1 class="font-semibold text-[35px] 2xl-1440:text-[21px] custom-span">Servicios adicionales</h1>
        <hr class="border border-2 border-black w-[427px]">

        <!-- Contenedor interno con los servicios adicionales -->
        <div class="flex w-[550px] p-[24px] flex-col items-start">
          <div class="py-[24px] 2xl-1440:py-3 flex items-center rounded-[10px]">
            <img src="/img/presentacion/4.svg" alt="Icono Atención 24/7" class="w-[64px] h-[64px] p-1 mr-4 bg-[#34A98F] rounded-[6px]">
            <p class="text-[29px] font-semibold 2xl-1440:text-[20px]">Atención 24/7</p>
          </div>
          <div class="py-[24px] 2xl-1440:py-3 flex items-center rounded-[10px]">
            <img src="/img/presentacion/1.svg" alt="Icono Implementación" class="w-[64px] h-[64px] p-1 mr-4 bg-[#34A98F] rounded-[6px]">
            <p class="text-[29px] font-semibold 2xl-1440:text-[20px]">Implementación<br>en menos de 24hs</p>
          </div>
          <div class="py-[24px] 2xl-1440:py-3 flex items-center rounded-[10px]">
            <img src="/img/presentacion/2.svg" alt="Icono Capacitación completa" class="w-[64px] h-[64px] p-1 mr-4 bg-[#34A98F] rounded-[6px]">
            <p class="text-[29px] font-semibold 2xl-1440:text-[20px]">Capacitación completa</p>
          </div>
          <div class="py-[24px] 2xl-1440:py-3 flex items-center rounded-[10px]">
            <img src="/img/presentacion/3.svg" alt="Icono Personalización del servicio" class="w-[64px] h-[64px] p-1 mr-4 bg-[#34A98F] rounded-[6px]">
            <p class="text-[29px] font-semibold 2xl-1440:text-[20px]">Personalización del servicio</p>
          </div>
        </div>

        <!-- Bloque de precios con transición desde abajo después de 2 segundos -->
        <transition name="slide-up" mode="out-in">
          <div v-if="showPriceDelayed" class="flex justify-end items-center gap-4 mt-6 mr-[150px]">
            <span class="text-[29px] font-semibold 2xl-1440:text-[20px] text-right custom-span">
              <span class="text-[41px] custom-span">8</span>.99€
            </span>
            <span class="text-[29px] font-semibold custom-span mt-2">Por habitación al mes</span>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';

const showPriceDelayed = ref(false);

// Activar el bloque de precios después de un retraso de 2 segundos
onMounted(() => {
  setTimeout(() => {
    showPriceDelayed.value = true;
  }, 400);
});
</script>

<style scoped>
.custom-span {
  color: inherit !important;
  font-family: 'Rethink Sans', sans-serif !important;
}

/* Estilos de la transición de "slide-up" para hacer que el precio suba desde abajo */
.slide-up-enter-active, .slide-up-leave-active {
  transition: transform 0.5s ease, opacity 0.5s ease;
}
.slide-up-enter-from {
  transform: translateY(100%);
  opacity: 0;
}
.slide-up-enter-to {
  transform: translateY(0);
  opacity: 1;
}
.slide-up-leave-from {
  transform: translateY(0);
  opacity: 1;
}
.slide-up-leave-to {
  transform: translateY(100%);
  opacity: 0;
}
</style>
